import React, { useState } from 'react';
import '../../New.css';
import logo from '../../images/elogo.png';
import { Link } from 'react-router-dom';
// import Coursepage from '../components/Coursepage';

const NewLandingPageNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const width = window.innerWidth;
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="newnavbar">
      <div className="newnavbar-container">
        <div className="newnavbar-left">
        {width > 960 && <Link to="/">  <img src={logo} alt="Logo" className="w-28 h-20" /></Link>}
        {width <= 960 && <Link to="/">  <img src={logo} alt="Logo" className="logo w-24 h-20" /></Link>}
        </div>
        <div className="md:flex hidden flex-row justify-around w-2/5 items-center">
          <Link className='text-black font-bold' to="/">Home </Link>
          <Link className='text-black font-bold' to="/aboutus">About Us </Link>
          <Link className='text-black font-bold' to="/contactus">Contact Us</Link>
          {/* <Link to="/Partnerships">Partnerships</Link> */}
          <Link className='text-black font-bold' to="/Services"> Services </Link>
        </div>
        <div className='newnavbar-right'>
          <Link to="/visaApply">  
            <button style={{ backgroundColor: '#32a4a8', borderRadius : '8px'}} 
              className="mbtn">Apply Now  
              <i className="fa fa-sign-in"></i>
            </button>
          </Link>
        </div>
        {width <= 960 && ( isMenuOpen == true ? 
            <Link onClick={handleMenuToggle}><i className='fa fa-close fa-2xl' style={{color: 'black'}} ></i></Link>
            :
            <Link onClick={handleMenuToggle}><i className='fa fa-bars fa-2xl' style={{color: 'black'}} ></i></Link>
          )
        }
        {isMenuOpen == true &&
          <div className='w-screen right-0 left-0 h-full fixed z-20 bg-black bg-opacity-20 ml-0 top-20 flex flex-row justify-end items-end'>
              <div className='w-4/5 h-full flex flex-col justify-start items-start bg-white px-2 py-2'>
                <Link to="/" className='text-black py-2 px-2'>Home</Link>
                <Link to="/aboutus" className='text-black py-2 px-2'>About Us</Link>
                <Link to="/contactus" className='text-black py-2 px-2'>Contact Us</Link>
                <Link to="/Services" className='text-black py-2 px-2'>Services</Link>
                <Link to="/visaApply" className='px-2 mx-2 py-2 flex flex-row justify-center items-center' style={{backgroundColor: '#32a4a8', borderRadius: '8px'}} >  
                    <span className='text-white'>Apply Now</span>  
                    <i className="fa fa-sign-in" style={{color: 'white'}}></i>
                </Link>
              </div>
          </div>
        }
      </div>
    </nav>
  );
};

export default NewLandingPageNavbar;

import React from 'react'
import PreNavbar from '../PreNavbar'

import NewLandingPageNavbar from './NewLandingPageNavbar'
import Newfooter from '../Newfooter'

import SectionFour from '../SectionFour'


import LandingSlide from './LandingSlide'
import { Padding } from '@mui/icons-material'
import zIndex from '@mui/material/styles/zIndex'
import IconGridSection from './IconGridSection'
import SimpleSection from './SimpleSection'
import VisaTypesSection from './VisaTypesSection'
import UniqueHeading from './UniqueHeading'
import UniqueServicesSection from './UniqueServicesSection'

import TestimonialSlides from './TestimonialSlides'
import GainAdmision from './GainAdmision'
import Case4 from '../../images/uae.jpg';
import CountingSection from '../CountingSection';
import VisaWhyChooseUs from './VisaWhyChooseUs'
import BeautifulStep from './BeautifulStep'
import TestiHeading from './TestiHeading'
import CopyrightSection from '../CopyrightSection'
import { Link } from 'react-router-dom'



const NewHomeLandingPage = () => {
  return (
    <div>
       <PreNavbar />
     <NewLandingPageNavbar />

    
    <LandingSlide />
     {/* <div  style={{padding: '4% 5% 5% 5%',
                    border : '1px solid #032e44' , 
                    backgroundColor: '#005fa3'} }> <VisaWhyChooseUs /> </div> */}
      <IconGridSection />
      <SimpleSection /> 
      <VisaTypesSection />
     <section className="cardinal-values">
        <h1 style={{padding: '40px',
                    textAlign : 'center' , 
                    color: '#0039af' ,
                    fontWeight : '900',
                    fontSize : '36px'} }>One Stop  for all your Visa requirements</h1>
      <div className="cardinalgrid-container">
        <div className="image-container">
          <img src={Case4} alt="Cardinal Value" className="image" />
        </div>
        <div className="text-container">
        <CountingSection />
         
          </div>
      </div>
    </section>
    <div className='fixed top-52 right-0 flex flex-col justify-between items-center w-20 h-56 bg-[#32a4a8] z-20'>
        <Link to ={`https://wa.me/${+2347072680329}`} className='flex flex-col justify-center items-center bg-green-600 w-full py-2'>
          <i className='fab fa-whatsapp' style={{color: 'white'}}></i>
          <span className='font-bold text-white'>Nigeria</span>
        </Link>
        <Link to ={`https://wa.me/${+447586645941}`} className='flex flex-col justify-center items-center bg-blue-800 w-full py-2'>
          <i className='fab fa-whatsapp' style={{color: 'white'}}></i>
          <span className='font-bold text-white'>UK</span>
        </Link>
        <Link to ={`https://wa.me/${+14169070794}`} className='flex flex-col justify-center items-center bg-[#FF0000] w-full py-2'>
          <i className='fab fa-whatsapp' style={{color: 'white'}}></i>
          <span className='font-bold text-white'>Canada</span>
        </Link>
    </div>
    <BeautifulStep />
      {/* <SectionFour /> */}
      <UniqueHeading />
      <UniqueServicesSection />
      {/* <TestiHeading />
      <TestimonialSlides /> */}
      <GainAdmision />

     <Newfooter /> 
      <CopyrightSection />
    </div>
  )
}

export default NewHomeLandingPage

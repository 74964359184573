import React from 'react'
import PreNavbar from './PreNavbar'
import '../App.css';
import '../New.css';

import Newfooter from './Newfooter';
import CopyrightSection from './CopyrightSection';

import logo1 from '../images/logo1.png';
import logo2 from '../images/logo2.jpg';
import logo3 from '../images/logo3.png';
import logo4 from '../images/logo4.png';
import logo5 from '../images/logo5.png';
import logo6 from '../images/logo6.png';
import logo7 from '../images/logo7.gif';
import logo8 from '../images/logo8.png';
import logo9 from '../images/logo9.jpg';
import logo10 from '../images/logo10.png';
import NewLandingPageNavbar from './newLandingPageHome/NewLandingPageNavbar';


const PartnershipsPage = () => {
  return (
    <div>
        <PreNavbar />
    
            <NewLandingPageNavbar />

        <div style={{ backgroundColor: '#ccc', textDecoration: 'none' }} >
        <div className="container">
    <div className="partnercontainer">
      <h1>Partnerships</h1>

      <p style={{ textAlign: 'center', textDecoration: 'none' }}>With strong links to the USA, Canada, Australia, Malaysia, and British education systems, 
      Golden Connect Limited connects Nigerian students to the respective institutions
         of their choice, and counsel them on their choice of studies. We owe our reputation to an unrivalled record of 
         guiding and preparing students through their institution’s selection, student visa processing, and supervising  the 
         completion of application forms.</p>
      {/* <section className="partners-grid">
        <div className="partnergrid">
          <h2>- CANADA</h2>
     <br></br>
          <ul>
            <li> <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>  Centennial College</li>
            <li> <i class="fa fa-chevron-circle-right" aria-hidden="true"></i> Fanshawe College, Canada</li>
            <li> <i class="fa fa-chevron-circle-right" aria-hidden="true"></i> Conestoga</li>
            <li> <i class="fa fa-chevron-circle-right" aria-hidden="true"></i> Windsor DIECL, Canada</li>
  
         
          </ul>
        </div>
        <div className="partnergrid">
          <h2>- UK</h2>
  <br></br>
          <ul>
          <li> <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>DIECL of the West England, Bristol</li>
            <li> <i class="fa fa-chevron-circle-right" aria-hidden="true"></i> Middlesex DIECL, UK</li>
            <li> <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>DIECL of Huddersfield</li>
            <li> <i class="fa fa-chevron-circle-right" aria-hidden="true"></i> Sheffield DIECL</li>
            <li> <i class="fa fa-chevron-circle-right" aria-hidden="true"></i> DIECL of South Wales, UK</li>
            <li> <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>Herriot Watt DIECL, UK</li>
           
          </ul>
        </div>
        
      </section> */}

    </div>
  </div>
  </div>  


{/* steps section */}

    <div className="container">
      <h3 style={{ textAlign:'center', fontWeight: '800', textDecoration: 'none' ,marginTop : '20px' , fontSize :'2rem' }}>- STUDY ABROAD IN FOUR SIMPLE STEPS</h3>
      <section className="steps-grid">
        <div className="step">
          <p>STEP  <i class="fa fa-chevron-circle-right" aria-hidden="true"></i></p>
          <h2>01</h2>
          <h4>Fill online application</h4>
          <br></br>
          <br></br>
          <p  style={{ fontWeight: '100', fontSize: '13px'  , textDecoration: 'none' }}>Start by filling the online application form on the website.</p>
        </div>
        <div className="step">
          <p>STEP <i class="fa fa-chevron-circle-right" aria-hidden="true"></i></p>
          <h2>02</h2>
          <h4>Schedule a meeting with an expert counsellor</h4>
          <br></br>
          <p style={{ fontWeight: '100', fontSize: '13px'  , textDecoration: 'none' }} >Our experienced counselors will contact you within 24hours.</p>
        </div>
        <div className="step">
          <p>STEP <i class="fa fa-chevron-circle-right" aria-hidden="true"></i> </p>
          <h2>03</h2>
          <h4>Finding a school of your choice</h4>
          <br></br>
          <p style={{ fontWeight: '100', fontSize: '13px'  , textDecoration: 'none' }} >Starting an appliation with chosen school</p>
        </div>
        <div className="step">
          <p>STEP <i class="fa fa-chevron-circle-right" aria-hidden="true"></i> </p>
          <h2>04</h2>
          <h4>Getting help with visa application</h4>
          <br></br>
          <p style={{ fontWeight: '100', fontSize: '13px'  , textDecoration: 'none' }}> We guide you through the steps to get your student visa. We'll help you relocate and settle down to your study abroad. </p>
        </div>
      </section>
    </div>



 
    <div className="container">
      <section className="imagelogo-grid">
        <div className="image-grid">
          <img src={logo1} alt="Image 1" />
        </div>
        <div className="image-grid">
          <img src={logo2} alt="Image 2" />
        </div>
        <div className="image-grid">
          <img src={logo3} alt="Image 3" />
        </div>
        <div className="image-grid">
          <img src={logo4} alt="Image 4" />
        </div>
        <div className="image-grid">
          <img src={logo5} alt="Image 5" />
        </div>
        <div className="image-grid">
          <img src={logo6} alt="Image 6" />
        </div>
        <div className="image-grid">
          <img src={logo7} alt="Image 7" />
        </div>
        <div className="image-grid">
          <img src={logo8} alt="Image 8" />
        </div>
        <div className="image-grid">
          <img src={logo9} alt="Image 9" />
        </div>
        <div className="image-grid">
          <img src={logo10} alt="Image 10" />
        </div>
      </section>
    </div>
         
    
        <Newfooter />
        <CopyrightSection />
    </div>
  )
}

export default PartnershipsPage

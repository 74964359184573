// src/components/LandingPage.js
import React from 'react';
import '../../NewlandingPage.css';
import { Link } from 'react-router-dom';

const LandingPage3 = () => {



  return (
    <div className="landing-page md:h-[80vh] h-[50vh]">
      <div className="background-image3"></div>
      <div className="content2 bg-black bg-opacity-80 md:w-[33vw] w-[70vw] ml-8" style={{ backgroundColor : '#0000003d' , padding: '10px' , borderRadius : '10px'}}>
        <h1 className="title2 text-lg text-white">Global Career Access</h1>
        <p className="description2 text-sm text-white">
          Global opportunities are waiting for you, and we're 
          here to help you seize them. Our mission is to provide 
          expert guidance and support for visa applications 
          related to work, business, and skilled migration. 
          Take the first step towards your international career.
        </p>
        <Link to="/visaApply"  onClick={() => {
              window.scroll(0, 0);
            }}> <button className="cta-button">Learn More</button></Link>
      </div>
    </div>
  );
};

export default LandingPage3;

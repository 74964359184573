// src/components/BeautifulStep.js
import React, { useState, useEffect, useRef } from 'react';
import '../../New.css';
import { Link } from 'react-router-dom';


const BeautifulStep = () => {
  const [buttonsVisible, setButtonsVisible] = useState([false, false, false, false, false]);
  const stepRef = useRef(null);

  useEffect(() => {
    const step = stepRef.current;

    const handleScroll = () => {
      const rect = step.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (rect.top < windowHeight / 1.5) {
        step.classList.add('stepfade-in'); // Add the 'fade-in' class when the section is in view

        // Use a timeout to stagger the appearance of buttons
        setTimeout(() => {
          setButtonsVisible([true, true, true, true, true]);
        }, 100);
      } else {
        step.classList.remove('stepfade-in'); // Remove the class when the section is out of view
        setButtonsVisible([false, false, false, false, false]); // Hide buttons when out of view
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="beautiful-step" ref={stepRef} style={{backgroundColor: '#32a4a8'}}>
        <h1 className='forcedh1' style={{ color: 'white', fontWeight : '900', padding : '60px'}} > We like to hear from you.</h1>
      <div className="step-row1">
      <Link to="/services"   onClick={() => {
              window.scroll(0, 0);
            }}>   <button className={ `step-button ${buttonsVisible[0] ? 'stepslide-in' : ''}`}> Convinced with our excellent services?</button> </Link> 
        {buttonsVisible[0] && <i className="arrow-icon">&#8595;</i>}
      </div>
      <div className="step-row2">
         <Link to="/visaApply" onClick={() => {
              window.scroll(0, 0);
            }}> <button className={`step-button ${buttonsVisible[1] ? 'stepslide-in' : ''}`}>Complete our online form today…</button></Link>
        {buttonsVisible[1] && <i className="arrow-icon">&#8595;</i>}
      </div>
      <div className="step-row3">
     <Link to="/contactus"  onClick={() => {
              window.scroll(0, 0);
            }}> <button className={`step-button ${buttonsVisible[2] ? 'stepslide-in' : ''}`}>Contact us today……</button></Link> 
        {buttonsVisible[2] && <i className="arrow-icon">&#8595;</i>}
      </div>
      <div className="step-row4">
       <Link to="/contactus"  onClick={() => {
              window.scroll(0, 0);
            }}>   <button className={`step-button ${buttonsVisible[3] ? 'stepslide-in' : ''}`}>Do You Have Questions?</button> </Link>
        {buttonsVisible[3] && <i className="arrow-icon">&#8595;</i>}
      </div>
      <div className="step-row5">
        <Link to="/contactus"  onClick={() => {
              window.scroll(0, 0);
            }}>  <button className={`step-button ${buttonsVisible[4] ? 'stepslide-in' : ''}`}>We will be delighted to answer them</button> </Link>
      </div>
    </div>
  );
};

export default BeautifulStep;

import React from 'react';
import '../App.css';
import '../New.css';

const CopyrightSection = () => {
  return (
    <div className="pre-navbar footerbg">
      <div className="left-section">
        <span>Copyright © 2022 GoldenConnect Limited.  Powered by  <a href='https://esolutiontec.com/'>E-Solution Technology</a></span>    
      </div>
      <div className="right-section">
        <a href="https://www.facebook.com/profile.php?id=61559013735678&mibextid=LQQJ4d"><i className="fab fa-facebook-f"></i></a>
        <a href="#"><i className="fab fa-twitter"></i></a>
        <a href="https://www.instagram.com/goldenconnectltd?igsh=MXBvd2d4YTkycXVy&utm_source=qr"><i className="fab fa-instagram"></i></a>
      </div>
    </div>
      
  );
};

export default CopyrightSection;

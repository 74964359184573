// src/components/LandingPage.js
import React from 'react';
import '../../NewlandingPage.css';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  return (
    <div className="landing-page md:h-[80vh] h-[50vh]">
      <div className="background-image"></div>
      <div className="content2 bg-black bg-opacity-75 md:w-[33vw] w-[70vw] ml-8">
        <h1 className="title2 text-white text-lg">Visa Applications Made Easy</h1>
        <p className="description2 text-base text-white">
        At Golden Connect Ltd, we are dedicated to simplifying the visa application process for people worldwide. Our goal is to make your journey hassle-free, so you can focus on your dreams, whether it's studying, working, or immigrating abroad.

        </p>
        < Link to="/visaApply"  onClick={ () => {
              window.scroll(0, 0);
            }}> <button className="cta-button">Learn More</button> </Link>
      </div>
    </div>
  );
};

export default LandingPage;

import React, { useState, useEffect } from 'react';
import TestimonialSection from './TestimonialSection';
import TestimonialSection2 from './TestimonialSection2';
import TestimonialSection3 from './TestimonialSection3'; // Import the third landing page component

function TestimonialSlides() {
  const [currentSlide, setCurrentSlide] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => {
        if (prevSlide === 1) return 2;
        if (prevSlide === 2) return 3;
        return 1;
      });
    }, 5000); // Change slides every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="App">
      {currentSlide === 1 ? <TestimonialSection /> : currentSlide === 2 ? <TestimonialSection2 /> : <TestimonialSection3 />}
    </div>
  );
}

export default TestimonialSlides;

import React from 'react';
import PreNavbar from './PreNavbar';
import NewLandingPageNavbar from './newLandingPageHome/NewLandingPageNavbar';
import Newfooter from './Newfooter';
import CopyrightSection from './CopyrightSection';
import { useState } from 'react';
import { countryList } from '../util/countries';
import imageCompression from 'browser-image-compression';
import axiosClient from '../axiosClient';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';

const Apply = () => {

    const baseURL = `https://goldenconnect.devstesting.com/api`;

    const [sending, setSending] = useState(false);

    const [inputs, setInputs] = useState({
        firstname: null,
        middlename: null,
        lastname: null,
        desired_country: null,
        course: null,
        email: null,
        phone: null,
        alt_phone: null,
        address: null,
        city: null,
        state: null,
        country: null,
        dob: null,
        cob: null,
        budget: 0,
        waec: null,
        passport: null
    });
    const [waec, setWaec] = useState();
    const [passport, setPassport] = useState();

    const navigate = useNavigate();

    const fileValidate = (file) => {
        if(file) {
            if (
                file.type === "image/png" ||
                file.type === "image/jpg" ||
                file.type === "image/jpeg" ||
                file.type === "application/pdf"
              ) {
                return true;
              } else {
                return false;
              }
        }
        else {
            return false;
        }
    };

    function isValidEmail(email) {
        // Regular expression pattern for validating email addresses
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        // Test the email string against the pattern
        return emailPattern.test(email);
    }

    function isValidDate(dob) {

        const userdate = new Date(dob);
        const date = new Date();

        if((date.getFullYear - userdate.getFullYear) < 16) {
            return false;
        } else {
            return true;
        }
    }

    const validateInput = async() => {
        if(inputs.firstname === null) {
            alert("Firstname Must Be a String");
            return false;
        }
        if(inputs.middlename === null) {
            alert("Middlename Must Be a String");
            return false;
        }
        if(inputs.lastname === null) {
            alert("Lastname Must Be a String");
            return false;
        }
        if(inputs.desired_country === null) {
            alert("Please Select a Country from the list of countries");
            return false;
        }
        if(inputs.course === null) {
            alert("Course Must Be a String");
            return false;
        }
        if(inputs.email === null || isValidEmail(inputs.email) == false) {
            alert("Email Address is Invalid or Empty");
            return false;
        }
        if(inputs.phone === null || inputs.phone.length < 10) {
            alert("Phone Number Must be at least 10 digits");
            return false;
        }
        if(inputs.alt_phone === null || inputs.alt_phone.length < 10) {
            alert("Alernative Phone Number Must be at least 10 digits");
            return false;
        }
        if(inputs.address === null) {
            alert("Address is Invalid or Empty");
            return false;
        }
        if(inputs.city === null) {
            alert("City is Invalid or Empty");
            return false;
        }
        if(inputs.state === null) {
            alert("State is Invalid or Empty");
            return false;
        }
        if(inputs.country === null) {
            alert("Nationality is Invalid or Empty");
            return false;
        }
        if(isValidDate(inputs.dob) == false) {
            alert("Date of Birth is Empty or Invalid. You must be at least 16 years old");
            return false;
        }
        if(inputs.cob === null) {
            alert("Country of Birth is Invalid or Empty");
            return false;
        }
        if(waec === null) {
            alert("Your Resume is Required");
            return false;
        }
        if(passport === null) {
            alert("Your International Passport is required");
            return false;
        }

        return true;
    }

    const handleChange = async(e) => {
        const name = e.target.id;
        const value = e.target.value;
        const type = e.target.type;

        let isValid = "";
        const options = {
            maxSizeMB: 3,
            maxWidthOrHeight: 1920
        }
        if(type === 'file' && name === 'waec') {
            if(fileValidate(e.target.files[0]) === true) {
                setWaec(e.target.files[0]);
                return;
            }
            alert("Nothing was uploaded");
            return;
        } else if (type === 'file' && name === 'passport') {
            if(fileValidate(e.target.files[0]) === true) {
                setPassport(e.target.files[0]);
                return;
            }
            alert("No Passport Uploaded");
            return;
        }
        setInputs({
            ...inputs,
            [name]: value
        });
        return;
    }
    
    const submitForm = async() => {
        if((await validateInput()).valueOf() === false) {
            return;
        } else {
            setSending("Uploading Files...");
            const formData = new FormData();
            formData.append('image1', waec);
            formData.append('image2', passport);
            //const data1 = new FormData();
            //data1.append('image1', passport);
            let resume = null;
            await axios.post(baseURL + '/fileUpload', formData)
            .then(async({data}) => {
                resume = data.docName;
                setPassport(data.imageName);
                setSending("Submitting Application...");
                await axios.post(baseURL + '/createNewApplication', {
                    firstname: inputs.firstname,
                    middlename: inputs.middlename,
                    lastname: inputs.lastname,
                    desired_country: inputs.desired_country,
                    desired_course: inputs.course,
                    email: inputs.email,
                    phone: inputs.phone,
                    alt_phone: inputs.alt_phone,
                    address: inputs.address,
                    city: inputs.city,
                    state: inputs.state,
                    country: inputs.country,
                    dob: inputs.dob,
                    cob: inputs.cob,
                    budget: inputs.budget,
                    waec: resume,
                    passport: data.imageName
                }).then(({data}) => {
                    setSending(false);
                    alert("Application Submitted");
                    navigate('/');
                    return;
                }).catch((error) => {
                    setSending(false);
                    alert("Server Error. Please Try again");
                    console.log(error);
                    return;
                });
            }).catch((error) => {
                alert("Server Error. Please Try again");
                setSending(false);
                console.log(error);
                return;
            });
        }
        
    }

    return (
        <div className = 'w-full h-auto overflow-x-hidden'>
            <PreNavbar />
            <NewLandingPageNavbar />
            {sending !== false && (
                    <div className='w-screen h-screen fixed top-0 left-0 z-20 flex flex-col justify-center items-center bg-black bg-opacity-40'>
                        <label className='font-inia font-bold text-3xl text-slate-50'>{sending}</label>
                    </div>
                )
            }
            <div className='w-full flex flex-col justify-center items-center py-6'>
                <label className='w-3/4 text-left font-bold text-2xl mb-6 text-slate-700'>Online Application Form</label>
                <div className='w-3/4 py-3 h-auto flex md:flex-row flex-col flex-wrap justify-between items-center'>
                    <div className='md:w-[31%] w-full h-16 mb-4 flex flex-col justify-evenly items-start'>
                        <label className='w-full text-left tracking-widest ml-0'>First Name *</label>
                        <input onChange={handleChange} type="text" name = 'firstname' id = 'firstname' placeholder='First Name' className='px-3 py-2 w-full rounded-md text-slate-600' required/>
                    </div>
                    <div className='md:w-[31%] w-full h-16 mb-4 flex flex-col justify-evenly items-start'>
                        <label className='w-full text-left tracking-widest ml-0'>Middle Name</label>
                        <input onChange={handleChange} type="text" name = 'middlename' id = 'middlename' placeholder='Middle Name' className='px-3 py-2 w-full rounded-md text-slate-600 border' />
                    </div>
                    <div className='md:w-[31%] w-full h-16 mb-4 flex flex-col justify-evenly items-start'>
                        <label className='w-full text-left tracking-widest ml-0'>Last Name *</label>
                        <input onChange={handleChange} type="text" name = 'lastname' id = 'lastname' placeholder='Last Name' className='px-3 py-2 w-full rounded-md text-slate-600 border' required/>
                    </div>
                    <div className='md:w-[46.5%] w-full h-16 mb-4 flex flex-col justify-evenly items-start'>
                        <label className='w-full text-left tracking-widest ml-0'>Desired Country of Study *</label>
                        <select onChange={handleChange} type="text" name = 'desired_country' id = 'desired_country' placeholder='Select Country to Study' 
                            className='px-3 py-2 w-full rounded-md text-slate-600 border' required>
                                <option value="">Desired Country</option>
                                {countryList.map((item, index) => (
                                    <option key = {index} value={item}>{item}</option>
                                ))
                                }
                        </select>
                    </div>
                    <div className='md:w-[46.5%] w-full h-16 mb-4 flex flex-col justify-evenly items-start'>
                        <label className='w-full text-left tracking-widest ml-0'>Desired Course of Study</label>
                        <input onChange={handleChange} type="text" name = 'course' id = 'course' placeholder='Desired Course of Study' className='px-3 py-2 w-full rounded-md text-slate-600'/>
                    </div>
                    <div className='md:w-[31%] w-full h-16 mb-4 flex flex-col justify-evenly items-start'>
                        <label className='w-full text-left tracking-widest ml-0'>Email Address *</label>
                        <input onChange={handleChange} type="email" name = 'email' id = 'email' placeholder='Email Address' className='px-3 py-2 w-full rounded-md text-slate-600' required/>
                    </div>
                    <div className='md:w-[31%] w-full h-16 mb-4 flex flex-col justify-evenly items-start'>
                        <label className='w-full text-left tracking-widest ml-0'>Primary Phone Number *</label>
                        <input onChange={handleChange} type="tel" name = 'phone' id = 'phone' placeholder='Phone Number' className='px-3 py-2 w-full rounded-md text-slate-600' required/>
                    </div>
                    <div className='md:w-[31%] w-full h-16 mb-4 flex flex-col justify-evenly items-start'>
                        <label className='w-full text-left tracking-widest ml-0'>Alternative Number</label>
                        <input onChange={handleChange} type="tel" name = 'alt_phone' id = 'alt_phone' placeholder='Phone Number 2' className='px-3 py-2 w-full rounded-md text-slate-600'/>
                    </div>
                    <div className='w-full h-16 mb-4 flex flex-col justify-evenly items-start'>
                        <label className='w-full text-left tracking-widest ml-0'>Address *</label>
                        <input onChange={handleChange} type="text" name = 'address' id = 'address' placeholder='Address' className='px-3 py-2 w-full rounded-md text-slate-600' required/>
                    </div>
                    <div className='md:w-[31%] w-full h-16 mb-4 flex flex-col justify-evenly items-start'>
                        <label className='w-full text-left tracking-widest ml-0'>City / Town *</label>
                        <input onChange={handleChange} type="text" name = 'city' id = 'city' placeholder='City / Town' className='px-3 py-2 w-full rounded-md text-slate-600' required/>
                    </div>
                    <div className='md:w-[31%] w-full h-16 mb-4 flex flex-col justify-evenly items-start'>
                        <label className='w-full text-left tracking-widest ml-0'>State *</label>
                        <input onChange={handleChange} type="text" name = 'state' id = 'state' placeholder='State' className='px-3 py-2 w-full rounded-md text-slate-600' required/>
                    </div>
                    <div className='md:w-[31%] w-full h-16 mb-4 flex flex-col justify-evenly items-start'>
                        <label className='w-full text-left tracking-widest ml-0'>Country *</label>
                        <select onChange={handleChange} type="text" name = 'country' id = 'country'
                            className='border px-3 py-2 w-full rounded-md text-slate-600' required>
                                <option value="">Country</option>
                                {countryList.map((item, index) => (
                                    <option key = {index} value={item}>{item}</option>
                                ))
                                }
                        </select>
                    </div>
                    <div className='md:w-[46.5%] w-full h-16 mb-4 flex flex-col justify-evenly items-start'>
                        <label className='w-full text-left tracking-widest ml-0'>Date of Birth *</label>
                        <input onChange={handleChange} type="date" name = 'dob' id = 'dob' placeholder='Date of Birth' className='px-3 py-2 w-full rounded-md text-slate-600' required/>
                    </div>
                    <div className='md:w-[46.5%] w-full h-16 mb-4 flex flex-col justify-evenly items-start'>
                        <label className='w-full text-left tracking-widest ml-0'>Country of Birth *</label>
                        <select onChange={handleChange} type="text" name = 'cob' id = 'cob' 
                            className='border px-3 py-2 w-full rounded-md text-slate-600' required>
                                <option value="">Country of Birth</option>
                                {countryList.map((item, index) => (
                                    <option key = {index} value={item}>{item}</option>
                                ))
                                }
                        </select>
                    </div>
                    <div className='w-full h-16 mb-4 flex flex-col justify-evenly items-start'>
                        <label className='w-full text-left tracking-widest ml-0'>Budget for School and Accomodation (USD) *</label>
                        <input onChange={handleChange} type="number" name = 'budget' id = 'budget' placeholder='Budget For School and Accomodation' className='px-3 py-2 w-full rounded-md text-slate-600' required/>
                    </div>
                    <div className='md:w-[46.5%] w-full h-24 mb-4 md:mt-0 mt-8 flex flex-col justify-evenly items-start'>
                        <label className='w-full text-left tracking-widest ml-0'>Upload Scan of CV/RESUME (PDF, JPG, PNG) *</label>
                        <input onChange={handleChange} type="file" name = 'waec' id = 'waec' className='px-3 py-2 w-full rounded-md text-slate-600' required/>
                    </div>
                    <div className='md:w-[46.5%] w-full h-24 mb-4 flex flex-col justify-evenly items-start'>
                        <label className='w-full text-left tracking-widest ml-0'>Upload Scan of Passport (PDF, JPG, PNG) *</label>
                        <input onChange={handleChange} type="file" name = 'passport' id = 'passport' className='px-3 py-2 w-full rounded-md text-slate-600' required/>
                    </div>
                    <button style={{backgroundColor: '#32a4a8'}} onClick={() => submitForm()} className='hover:bg-slate-900 md:w-[46.5%] w-full mt-4 mb-4 px-2 py-2 rounded-md shadow-md font-bold text-white text-center'>
                        Submit Application
                    </button>
                </div>
            </div>
            <Newfooter />
            <CopyrightSection />
        </div>
    )   
}

export default Apply;
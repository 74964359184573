// src/components/LandingPage.js
import React from 'react';
import '../../NewlandingPage.css';
import { Link } from 'react-router-dom';

const LandingPage4 = () => {
   


  return (
    <div className="landing-page md:h-[80vh] h-[50vh]">
      <div className="background-image4" ></div>
      <div className="content2 bg-black bg-opacity-80 md:w-[33vw] w-[70vw] ml-8" style={{backgroundColor : '#0000003d' , padding: '10px' , borderRadius : '10px'}}>
        <h1 className="title2 text-white text-lg">Family Reunification & Immigration</h1>
        <p className="description2 md:text-base text-xs text-white">
          Family is at the heart of what we do. 
          Whether you're looking to reunite with 
          loved ones or start a new life in a 
          different country, our team is committed 
          to making the immigration process smoother. 
          We're here to bring families together and 
          help you build a brighter future.
        </p>
        <Link to="/visaApply"  onClick={() => {
              window.scroll(0, 0);
            }}> <button className="cta-button">Learn More</button> </Link>
      </div>
    </div>
  );
};

export default LandingPage4;
import React from 'react'

const AboutUsShowcase = () => {
  return (
    <div className="student-support" style={{backgroundColor: '#32a4a8'}}>
      <h2>ABOUT GOLDEN CONNECT</h2>
      <p>
      GoldenConnect Limited, an affiliate of GoldenConnect Limited is registered with the Nigerian Corporate Affairs Commission in the year 2018 and has the registration number RC 1101931. In the year 2019, the organization expanded to Suite 5-6, 5th Floor, Block B, Lister House, M.K.O. Abiola Way, Ring Road, Ibadan, Oyo State, Nigeria.

      <br></br> <br></br>  From our three branches of office situated in Nigeria (Oyo – State and Lagos – State) and Canada, we work with our partner institutions across the globe providing the most sought-after outstanding educational achievements and long-lasting sense of belonging experience. We love to see participants have the privilege of exploring advanced education in diverse countries across the globe.

      <br></br> <br></br>  Knowing that Visa Application processes can be extremely strenuous has been perceived by us as one of the greatest burdens participants have and we are often delighted to relieve them of the complexity of the situation they encounter through our various services. This is provided through counseling and coaching sessions, detailed information about various options relevant to their education and school of interest, professional templates to guide their letter of intent and visa processes.

      <br></br> <br></br>  GoldenConnect Limited also provides information about available opportunities like scholarships and internship experiences that can help their clients explore all the benefits they have while studying to the fullest.

      <br></br> <br></br>  Our expertise has been proven by 20 years of serving thousands of participants in Africa, Europe, the United Kingdom, and Canada with the best services that make them realize their educational potentials. This has made us exceptional when it comes to supporting participants and family goals to study and relocate abroad.</p>
    </div>
  )
}

export default AboutUsShowcase

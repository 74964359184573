import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';

import WhoWeArePage from './components/WhoWeArePage';
import PartnershipsPage from './components/PartnershipsPage';
import ServicesPage from './components/ServicesPage';
import NewHomeLandingPage from './components/newLandingPageHome/NewHomeLandingPage';
import Apply from './components/Apply';
import AboutUsPage from './components/AboutUsPage';
import ContactUsPage from './components/ContactUsPage';


function App() {
  return (
      <Routes >
      {/* <ScrollToTop /> */}
            <Route path="/" element={<NewHomeLandingPage />} />
            <Route path="aboutus" element={<AboutUsPage />} />
          
            <Route path = "WhoWeAre" element = {<WhoWeArePage />} />
            <Route path = "Partnerships" element = {<PartnershipsPage />} />
            <Route path = "Services" element = {<ServicesPage />} />
            <Route path = "visaApply" element = {<Apply />} />
            <Route path = "contactus" element = {<ContactUsPage />} />
      </Routes>
  );
}

export default App;

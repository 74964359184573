import React from 'react';
import PreNavbar from './PreNavbar'
import '../App.css';
import '../New.css';
import Newfooter from './Newfooter';
import CopyrightSection from './CopyrightSection';
import AboutUsShowcase from './AboutUsShowcase';
import ThreeGridSection from './ThreeGridSection';
import ThreeBoxes from './ThreeBoxes';
import NewLandingPageNavbar from './newLandingPageHome/NewLandingPageNavbar';

const AboutUsPage = () => {
  return (
    <div>
          <PreNavbar />
          <NewLandingPageNavbar />
  
              <AboutUsShowcase />
              {/* <ThreeGridSection /> */}
              <ThreeBoxes />
        <Newfooter />
        <CopyrightSection />
    </div>
  )
}

export default AboutUsPage

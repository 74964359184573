// src/components/VisaTypesSection.js
import React, { useEffect, useRef } from 'react';
import '../../NewlandingPage.css';
import passport from '../../images/studentvisa.jpg'
import visa2 from '../../images/independent.jpg'
import visa3 from '../../images/visitor.jpg'
import visa4 from '../../images/visa.png'
import visa5 from '../../images/express.jpg'
import { Link } from 'react-router-dom';

const VisaTypesSection = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const section = sectionRef.current;

    const handleScroll = () => {
      const rect = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (rect.top < windowHeight / 1.5) {
        section.classList.add('visafade-in'); // Add the 'fade-in' class when the section is in view
      } else {
        section.classList.remove('visafade-in');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className="visa-types-section" ref={sectionRef} style={{backgroundColor: '#32a4a8'}}>
      <div className="visa-type">
        < img src={passport}  alt="Visa 1" />
        <h3>Student Visa</h3>
        <p> You can apply for a Student visa to study in the UK if you’re 16 or over . We help our students process student visa to study in any of our partnered schools of their choices. Start your process today by reaching out to us</p>
        <a href="#" className="contact-button" style={{ marginTop: '50px', backgroundColor: '#32a4a8'}}>
          Contact Us
          <span>&rarr;</span>
        </a>
      </div>
      <div className="visa-type">
      <img src={visa2}  alt="Visa 1" />
        <h3>Independent Visa</h3>
        <p> This type of visa that allows individuals to live, work, or study in a foreign country without the need for a specific job offer, family sponsorship, or educational institution enrollment.</p>
        <Link to="/contactus" onClick={() => {
              window.scroll(0, 0);
            }}> <a href="#" className="contact-button" style={{ marginTop: '', backgroundColor: '#32a4a8'}}>
          Contact Us
          <span>&rarr;</span>
        </a> </Link>
      </div>
      <div className="visa-type">
      <img src={visa3}  alt="Visa 1" />
        <h3>Visit Visa</h3>
        <p>This is a type of temporary travel document issued to foreign nationals who wish to enter the country for tourism, business, family visits, or other short-term purposes. We can help make that happens today.</p>
        <Link to="/contactus"  onClick={() => {
              window.scroll(0, 0);
            }}> <a href="#" className="contact-button" style={{ marginTop: '', backgroundColor: '#32a4a8'}}>
          Contact Us
          <span>&rarr;</span>
        </a> </Link>
      </div>
      <div className="visa-type">
      <img src={visa5}  alt="Visa 1" />
        <h3>Express entry</h3>
        <p>Canada Express Entry is the most popular route for overseas skilled professionals to settle in Canada permanently. It is a points-based system that allots points based on skills, work experience, Canadian employment status, and provincial/territorial nomination. We would like to help you with that.</p>
        <Link to="/contactus"  onClick={() => {
              window.scroll(0, 0);
            }}> <a href="#" className="contact-button" style={{ marginTop: '', backgroundColor: '#32a4a8'}}>
          Contact Us
          <span>&rarr;</span>
        </a> </Link>
      </div>
      {/* <div className="visa-type">
      <img src={visa4}  alt="Visa 1" />
        <h3> DIECL Study route</h3>
        <p>Among other benefits,  Whether it is about getting certified, improving your skills, or
              successfully contributing towards your Continuous Professional Development,
              Diptot International Education Consult Limited (DIECL) Training helps you
              achieve more for less.
              </p>
              <Link to="/contactus"  onClick={() => {
              window.scroll(0, 0);
            }}> <a href="#" className="contact-button" style={{ marginTop: '', }}>
          Contact Us
          <span>&rarr;</span>
        </a> </Link>
      </div> */}
    </section>
  );
};

export default VisaTypesSection;

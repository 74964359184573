import axios from 'axios';

const axiosClient = axios.create({
    //baseURL: `${process.env.BASE_URL}/api`
    baseURL: `https://goldenconnect.devstesting.com/api`
});

/*axiosClient.interceptors.request.use((config) => {
    const token = '28ABCDEFGHIJKLMNOPQRSTUVWXYZ18';
    config.headers.Authorization = `Bearer ${token}`;
    config.withCredentials = false;
    return config;
});

axiosClient.interceptors.response.use((response) => {
    return response;
}, (error) => {
    try {
        const { response } = error;
        if(response.status === 401) {
            localStorage.removeItem('ACCESS_TOKEN');
        }    
        else {}
    } catch (e) {
        console.error(e.response);
    }   
    throw error;
})*/

export default axiosClient;
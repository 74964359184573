import React from 'react'
import PreNavbar from './PreNavbar'
import '../App.css';
import '../New.css';

import Newfooter from './Newfooter';
import CopyrightSection from './CopyrightSection';
import Case from '../images/dubai-transformed.png';
import Case2 from '../images/dubai-transformed.png';
import Case3 from '../images/dubai-transformed.png';
import CountingSection from './CountingSection';


const WhoWeArePage = () => {
  return (
    <div>
       <PreNavbar />
     
        <section className="cardinal-values">
      <div className="cardinalgrid-container">
        <div className="image-container">
          <img src={Case} alt="Cardinal Value" className="image" />
        </div>
        <div className="text-container">
          <h6>WHO WE ARE</h6>
          <p>Diptot International Education Consult Limited, an affiliate of Diptot Communications Limited is registered with the Nigerian Corporate Affairs Commission in the year 2012 and has the registration number RC 1101931.<br></br>  <br></br>Her branches in Nigeria are situated in 49, Oba – Adesida road beside Ricabim House, Akure, Ondo – State and second floor, suite 4, 11 Lateef Jakande Road, Ikeja, Lagos – State. In the year 2019, the organization expanded to 1460 the Queensway Unit No 112 Etobicoke, Toronto Canada – M8z 1s4 and got registered with the Ontario Corporation under the laws of the Province of Ontario, Canada with the no 002699052</p>
        </div>
      </div>
    </section>

    <section className="newnext-section">
      <div className="newgrid-container">
        <div className="newgrid-item">
          
          <h5 style={{ color: 'black', }}>The early phase of Diptot International Education Consult after its establishment as an affiliate of Diptot Communications, following the amendment of the Memorandum of Association to reflect education/study abroad packaging for students as part of the objectives of the company brought an unprecedented growth in international participants’ recruitment by 60% to a total of hundred and ten students for ten partner institutions.<br></br> <br></br> This marked an unprecedented foundational achievement in in the organization’s educational services pathway.</h5>
        </div>
        <div className="newgrid-item">
        <div className="image-container">
          <img src={Case2} alt="Cardinal Value" className="image" />
        </div>
        </div>
      </div>
    </section>

    <section className="cardinal-values">
      <div className="cardinalgrid-container">
        <div className="image-container">
          <img src={Case3} alt="Cardinal Value" className="image" />
        </div>
        <div className="text-container">
        <CountingSection />
         
          </div>
      </div>
    </section>

    <section className="three-grid-section plain-bg">
   
      <div className='container' style={{ color: ' white', }}>
      <h3 style={{ color: 'white', marginBottom: '50px', fontWeight: '800'}}>Our Competencies</h3>
      <div className="grid3-container">
                    <div className="">
                     
                     
                       <h5><i class="fa fa-chevron-circle-right" aria-hidden="true"></i>   Effective training methodology</h5> <br></br>
                        
                     </div>
                    <div className="">
                   
                   
                       <h5> <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>   Life changing counseling approach</h5><br></br> 
                    

                    </div>
                    <div className="">
                 
                  
                       <h5> <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>   Quality partnership management</h5><br></br>
                    
                    </div>
                 
      </div>
      <div className="acadcoursegrid-container" style={{ marginTop: '40px',}}>
          <div className="acadcoursegrid-div">
      <h6>Our vision is to be the best educational consultancy outfit in Africa, committed to mitigating limitations imposed by the African Society, thereby creating an enabling platform that will continuously promote mass literacy amongst Africa’s future generation</h6>

      </div>

      <div className="acadcoursegrid-div">
      <h6>Our mission is to proffer solutions to all possible challenges that may be encountered by students as they journey down their academic pursuit by providing quality advisory services that meet international standards</h6>
      </div>
      </div>


      </div>


    </section>




        <Newfooter />
        <CopyrightSection />
        
    </div>
  )
}

export default WhoWeArePage

import React from 'react';
// import '../App.css';
import '../New.css';
import { Link } from 'react-router-dom';

const PreNavbar = () => {
  return (
    <div className="pre-navbar" style={{backgroundColor: '#32a4a8'}}>
        
      <div className="left-section">
        <Link to={`mailto:info@goldenconnectlimited.com`}>
          <span><i className="fa fa-envelope"></i> info@goldenconnectlimited.com</span>
        </Link>
        <Link to={`tel:${+2347072680329}`}>
          <span><i className="fa fa-phone"></i> +234-7072-680-329</span>
        </Link>
        <Link to={`https://wa.me/${+2347072680329}`}>
            <span><i className='fab fa-whatsapp'></i> +2347072680329</span>
        </Link>
      </div>
      <div className="right-section">
        <a href="https://www.facebook.com/profile.php?id=61559013735678&mibextid=LQQJ4d"><i className="fab fa-facebook-f"></i></a>
        <a href="#"><i className="fa-brands fa-twitter"></i></a>
        <a href="https://www.instagram.com/goldenconnectltd?igsh=MXBvd2d4YTkycXVy&utm_source=qr"><i className="fab fa-instagram"></i></a>
      </div>
      </div>

  );
};

export default PreNavbar;

// src/components/LandingPage2.js
import React from 'react';
import '../../NewlandingPage.css';
import { Link } from 'react-router-dom';

const LandingPage2 = () => {
  return (
    <div className="landing-page2 md:h-[80vh] h-[50vh]">
      <div className="background-image5"></div>
      <div className="content2 bg-black bg-opacity-80 md:w-[33vw] w-[70vw] ml-8">
        <h1 className="title2 text-white text-lg">Seamless Travel Visas</h1>
        <p className="description2 text-base text-white">
       
        Exploring new destinations should be exciting, not stressful. Our expertise lies in simplifying the visa application process for tourists and travelers. With our assistance, you can embark on seamless adventures to your dream destinations.
        </p>
        <Link to="/visaApply"  onClick={() => {
              window.scroll(0, 0);
            }}> <button className="cta-button2">Learn More</button></Link>
      </div>
    </div>
  );
};

export default LandingPage2;

import React from 'react';
import PreNavbar from './PreNavbar'
import '../App.css';
import '../New.css';

import Newfooter from './Newfooter';
import CopyrightSection from './CopyrightSection';
import ContactForm from './ContactForm';
import TwoGridSection from './TwoGridSection';

import NewLandingPageNavbar from './newLandingPageHome/NewLandingPageNavbar';

const ContactUsPage = () => {
  return (
    <div>
    <PreNavbar />
    <NewLandingPageNavbar />
          <ContactForm />
        
    <Newfooter />
    <CopyrightSection />
</div>
  )
}

export default ContactUsPage

import React from 'react';
import '../App.css';


const ThreeBoxes = () => {
  return (
    <section className="three-grid-section plain-bg" style={{backgroundColor: '#32a4a8'}}>
      <div className='container' style={{backgroundColor: '#32a4a8'}}>
      <div className="grid3-container">
                    <div className="grid3-item">
                       
                       <h4 className='big-icon'><i class="fa fa-briefcase" aria-hidden="true"></i></h4>
                       <h4>Experienced</h4>
                       <p> We have been helping client's study in International Universities since 2008 and supporting them with relevant trainings and resources to suit their career goals.</p> <br></br>
                        
                     </div>
                    <div className="grid3-item">
                   
                    <h4 className='big-icon'> <i class="fa fa-list" aria-hidden="true"></i> </h4>
                    <h4>Extensive Options</h4>
                       <p>With GoldenConnect Limited, there are access to Pathway Programs, Diploma Programs, College and GoldenConnect Limited options in the US, Canada, UK, Europe, and Malaysia</p><br></br> 
                    

                    </div>
                    <div className="grid3-item">
                 
                    <h4 className='big-icon'><i class="fa fa-star" aria-hidden="true"></i></h4>
                   <h4>Accredited </h4>
                       <p> GoldenConnect Limited is an accredited UN Visa agent and a British Council trained agent.</p><br></br>
                    
                    </div>
                    <div className="grid3-item">
                 
                 <h4 className='big-icon'><i class="fa fa-user" aria-hidden="true"></i></h4>
                 <h4>Personalized Service </h4>
                    <p> Personalized approach is used in consulting with each client.</p><br></br>
                 
                 </div>

                 <div className="grid3-item">
                 
                 <h4 className='big-icon'><i class="fas fa-check"></i></h4>
                 <h4>Reliable</h4>
                    <p> GoldenConnect Limited is driven by values of transparency, integrity, and professionalism..</p><br></br>
                 
                 </div>
      </div>
      </div>
    </section>
  );
};

export default ThreeBoxes;
// src/components/SimpleSection.js
import React, { useEffect, useRef } from 'react';
import '../../NewlandingPage.css';

const SimpleSection = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const section = sectionRef.current;

    const handleScroll = () => {
      const rect = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (rect.top < windowHeight / 1.5) {
        section.classList.add('fade-in'); // Add the 'fade-in' class when the section is in view
      } else {
        section.classList.remove('fade-in'); // Remove the class when the section is out of view
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className="simple-section" ref={sectionRef} style={{backgroundColor: '#32a4a8'}}>
      <h5 className="navy-blue-text forcedh1">GET YOUR VISA WITH US</h5>
      <h6 className="center-aligned">Choose from our visa categories</h6>
      <h6 className="center-aligned">We make this process easy for students, helping them through the entire process from application to receipt of the visa.</h6>
    </section>
  );
};

export default SimpleSection;

import React from 'react'

import '../../App.css';
import '../../New.css';


import logo1 from '../../images/logo1.png';
import logo2 from '../../images/logo2.jpg';
import logo3 from '../../images/logo3.png';
import logo4 from '../../images/logo4.png';
import logo5 from '../../images/logo5.png';
import logo6 from '../../images/logo6.png';
import logo7 from '../../images/logo7.gif';
import logo8 from '../../images/logo8.png';
import logo9 from '../../images/logo9.jpg';
import logo10 from '../../images/logo10.png';
import logo11 from '../../images/logo11.png';
import logo12 from '../../images/logo12.jpg';



const GainAdmision = () => {
  return (
    <div>
    <div className="container">
      <h3 style={{ textAlign:'center', fontWeight: '800', textDecoration: 'none' ,marginTop : '20px' , fontSize :'2rem' }}>- STUDY ABROAD IN FOUR SIMPLE STEPS</h3>
      <section className="steps-grid">
        <div className="step">
          <p>STEP  <i class="fa fa-chevron-circle-right" aria-hidden="true"></i></p>
          <h2>01</h2>
          <h4>Fill online application</h4>
          <br></br>
          <br></br>
          <p  style={{ fontWeight: '100', fontSize: '13px'  , textDecoration: 'none' }}>Start by filling the online application form on the website.</p>
        </div>
        <div className="step">
          <p>STEP <i class="fa fa-chevron-circle-right" aria-hidden="true"></i></p>
          <h2>02</h2>
          <h4>Schedule a meeting with an expert counsellor</h4>
          <br></br>
          <p style={{ fontWeight: '100', fontSize: '13px'  , textDecoration: 'none' }} >Our experienced counselors will contact you within 24hours.</p>
        </div>
        <div className="step">
          <p>STEP <i class="fa fa-chevron-circle-right" aria-hidden="true"></i> </p>
          <h2>03</h2>
          <h4>Finding a school of your choice</h4>
          <br></br>
          <p style={{ fontWeight: '100', fontSize: '13px'  , textDecoration: 'none' }} >Starting an appliation with chosen school</p>
        </div>
        <div className="step">
          <p>STEP <i class="fa fa-chevron-circle-right" aria-hidden="true"></i> </p>
          <h2>04</h2>
          <h4>Getting help with visa application</h4>
          <br></br>
          <p style={{ fontWeight: '100', fontSize: '13px'  , textDecoration: 'none' }}> We guide you through the steps to get your student visa. We'll help you relocate and settle down to your study abroad. </p>
        </div>
      </section>
    </div>



 
    <div className="container">
      <section className="imagelogo-grid">
        <div className="image-grid">
          <img src={logo11} alt="Image 9" />
        </div>
        <div className="image-grid">
          <img src={logo12} alt="Image 10" />
        </div>
        <div className="image-grid rounded">
          <img src={logo1} alt="Image 1" />
        </div>
        <div className="image-grid rounded">
          <img src={logo2} alt="Image 2" />
        </div>
        <div className="image-grid rounded">
          <img src={logo3} alt="Image 3" />
        </div>
        <div className="image-grid">
          <img src={logo4} alt="Image 4" />
        </div>
        <div className="image-grid">
          <img src={logo5} alt="Image 5" />
        </div>
        <div className="image-grid">
          <img src={logo6} alt="Image 6" />
        </div>
        <div className="image-grid">
          <img src={logo7} alt="Image 7" />
        </div>
        <div className="image-grid">
          <img src={logo8} alt="Image 8" />
        </div>
        <div className="image-grid">
          <img src={logo9} alt="Image 9" />
        </div>
        <div className="image-grid">
          <img src={logo10} alt="Image 10" />
        </div>
      </section>
    </div>

    </div>
  )
}

export default GainAdmision

import React from 'react';
import '../New.css'; // You can style your component in a separate CSS file
import CountAdmissionProcessed from './CountAdmissionProcessed';
import CountYears from './CountYears';
import CountVisa from './CountVisa';
import CountInternationalPresence from './CountInternationalPresence';

const CountingSection = () => {
  const data = [
    {
      title: <CountAdmissionProcessed />, // Embed a React component
      subtitle: 'Admission Processed',
      description: 'We have processed over 280 admissions to prestigious universities in Canada, USA, UK, Europe and Malaysia.',
    },
    {
      title:  <CountVisa /> ,
      subtitle: 'Visas Secured',
      description: '350 students Visas and 100 visitors and relocation visas. Our consultancy services have helped clients secure travel visas for various purposes.',
    },
    {
        title:  <CountYears />, // Embed a React component
        subtitle: 'Years of Experience',
        description: 'GoldenConnectLtd has over 6 years of experience in providing valuable services to clients and has expanded to international locations.',
      },
      {
        title: <CountInternationalPresence />,
        subtitle: 'International Presence',
        description: 'GoldenConnectLtd has a strong presence in Nigeria, Canada and the United Kingdom',
      },
    // Add more data objects as needed
  ];

  return (
    <div className="whowearecontainer">
      {data.map((item, index) => (
        <div key={index} className="whoweareitem">
         <h1> {item.title}</h1>
          <h5>{item.subtitle}</h5>
          <p>{item.description}</p>
        </div>
      ))}
    </div>
  );
};

export default CountingSection;

// src/components/UniqueServicesSection.js
import React, { useEffect, useRef } from 'react';
import '../../NewlandingPage.css';



const UniqueServicesSection = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const section = sectionRef.current;

    const handleScroll = () => {
      const rect = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (rect.top < windowHeight / 1.5) {
        section.querySelectorAll('.unique-service').forEach((service, index) => {
          service.style.transitionDelay = `${index * 0.2}s`; // Add a delay for each service
          service.classList.add('flip-in'); // Add the 'flip-in' class when in view
        });
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className="unique-services-section" ref={sectionRef}>
      <div className="unique-service" style={{borderColor: '#32a4a8'}}>
        <div className="serviceicon"><i class="fa fa-vcard" aria-hidden="true"></i></div>
        <h3>Visa Processing</h3>
        <p>Different countries have different visa requirements. We make this process easy for students, helping them through the entire process from application to receipt of the visa.</p>
      </div>
      <div className="unique-service" style={{borderColor: '#32a4a8'}}>
        <div className="serviceicon"><i class="fa fa-book" aria-hidden="true"></i></div>
        <h3>Institution Application</h3>
        <p>We help students pick the right path to their chosen career, as well as helping them to find the right institution in the right country to best support their dreams.</p>
      </div>
      <div className="unique-service" style={{borderColor: '#32a4a8'}}>
        <div className="serviceicon"><i class="fa fa-address-book" aria-hidden="true"></i></div>
        <h3>Educational Counselling</h3>
        <p>We focus on the professional preparation of students for personal, social, educational, and vocational development abroad at top tertiary institutions in a variety of countries.</p>
      </div>
      <div className="unique-service" style={{borderColor: '#32a4a8'}}>
        <div className="serviceicon"><i class="fa fa-phone" aria-hidden="true"></i></div>
        <h3>Flight Booking Service</h3>
        <p>We help students pick the right path to their chosen career, as well as helping them to find the right institution in the right country to best support their dreams.</p>
      </div>
      <div className="unique-service" style={{borderColor: '#32a4a8'}}>
        <div className="serviceicon"><i class="fa fa-user" aria-hidden="true"></i></div>
        <h3>CV support</h3>
        <p>Let us help you do it right. We have the experience to make sure our students submit the best possible applications and have the highest chance of getting into their dream college.</p>
      </div>
      <div className="unique-service" style={{borderColor: '#32a4a8'}}>
        <div className="serviceicon"><i class="fa fa-graduation-cap" aria-hidden="true"></i></div>
        <h3>Training</h3>
        <p>Let us help you do it right. We have the experience to make sure our students submit the best possible applications and have the highest chance of getting into their dream college.</p>
      </div>
    </section>
  );
};

export default UniqueServicesSection;

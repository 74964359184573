// src/components/LandingPage2.js
import React from 'react';
import '../../NewlandingPage.css';
import { Link } from 'react-router-dom';

const LandingPage2 = () => {
  return (
    <div className="landing-page2 md:h-[80vh] h-[50vh]">
      <div className="background-image2"></div>
      <div className="content2 bg-black bg-opacity-80 md:w-[33vw] w-[70vw] ml-8">
        <h1 className="title2 text-white text-lg">Study Abroad With Ease</h1>
        <p className="description2 text-white text-sm">
        We believe that education knows no borders. That's why we specialize in assisting students with visa applications, ensuring that you have the support you need to embark on your educational journey abroad. Your dreams are our priority.
        </p>
        <Link to="/visaApply"  onClick={() => {
              window.scroll(0, 0);
            }}> <button className="cta-button2">Learn More</button> </Link>
      </div>
    </div>
  );
};

export default LandingPage2;

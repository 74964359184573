import React from 'react'
import PreNavbar from './PreNavbar'
import '../App.css';
import '../New.css';

import Newfooter from './Newfooter';
import CopyrightSection from './CopyrightSection';
import { Link } from 'react-router-dom';
import SimpleSection from './newLandingPageHome/SimpleSection';
import VisaTypesSection from './newLandingPageHome/VisaTypesSection';
import NewLandingPageNavbar from './newLandingPageHome/NewLandingPageNavbar';

const ServicesPage = () => {
  return (
    <div>
        <PreNavbar />
     <NewLandingPageNavbar />
      

       <SimpleSection />
   
       <VisaTypesSection />

       <div style={{ backgroundColor: '#0049AF', display : 'flex', justifyContent : 'center' }}>  <Link to="/visaApply"   onClick={() => {
              window.scroll(0, 0);
            }}><button style={{ backgroundColor: '#32a4a8', borderRadius: '10px', borderBottom : '2px solid #001f3f'}} >Find Out More on Our Visa Application</button> </Link>  </div>
        <div style={{ backgroundColor: '#ccc', textDecoration: 'none' }}>
        <div className="container">
    <div className="servicecontainer">
      <h1 className='forcedh1'>Other Services</h1>
      <section className="services-grid">
        <div className="servicesgrid">
          <h2>-International Exams</h2>
       <br></br>   <p>GoldenConnect Ltd offers academic trainings. These include</p>
          <ul>
            <li> <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>  TOEFL</li>
            <li> <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>  IELTS</li>
            <li> <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>  GRE</li>
            <li> <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>  GMAT</li>
            <li> <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>  Tutorial booking and Test booking.</li>
         
          </ul>
        </div>
        <div className="servicesgrid">
          <h2>- Trainings</h2>
        <br></br>  <p>GoldenConnect Ltd  provides training services for professionals</p>
          <ul>
          <li> <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>  Data Analyst Fundamental</li>
            <li> <i class="fa fa-chevron-circle-right" aria-hidden="true"></i>Digital Content Executive</li>
            <li> <i class="fa fa-chevron-circle-right" aria-hidden="true"></i> Web Development</li>
            <li> <i class="fa fa-chevron-circle-right" aria-hidden="true"></i> Software Engineering</li>
            <li> <i class="fa fa-chevron-circle-right" aria-hidden="true"></i> Mobile Application Development</li>
          
           
          </ul>
        </div>
        {/* <div className="servicesgrid">
          <h2>- Payment</h2>
        <br></br>  <p>Canada
              <br></br>Olar@diptotinternationalconsult.com</p>
           
           <br></br>   <p>Nigeria
                  <br></br>  Account Name: Diptot Intl Education Consult ltd
                  <br></br>  Bank: Access Bank
                  <br></br>  Account Number: 0060019198</p>
        </div> */}
      </section>
    
    </div>
  </div>
  </div>     


        <Newfooter />
        <CopyrightSection />

    </div>
  )
}

export default ServicesPage

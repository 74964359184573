

import React, { useState, useEffect } from 'react';
import LandingPage from './LandingPage';
import LandingPage2 from './LandingPage2';
import LandingPage3 from './LandingPage3';
import LandingPage4 from './LandingPage4'; 
import LandingPage5 from './LandingPage5';  

function LandingSlide() {
  const [currentSlide, setCurrentSlide] = useState(0); // Start with the first slide (index 0)
  const slides = [<LandingPage />, <LandingPage2 />, <LandingPage3 /> , <LandingPage4 /> ,  <LandingPage5 />]; // Add your components to this array

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
    }, 5000); // Change slides every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="App">
      {slides[currentSlide]}
    </div>
  );
}

export default LandingSlide;

// src/components/GridSection.js
import React from 'react';
import '../../NewlandingPage.css';

const IconGridSection = () => {
  return (
    <div  className='textCentr' style={{backgroundColor: '#32a4a8'}}>

          <h1 className='forcedh1'>Solutions We Provide:</h1>
    <section className="icongrid-section" style={{backgroundColor: '#32a4a8'}}>
      
      <div className="icongrid-item">
        <div className="seticon"><i class="fa fa-vcard" aria-hidden="true"></i></div>
        <div className="text">Visa Processing</div>
      </div>
      <div className="icongrid-item">
        <div className="seticon"><i class="fa fa-suitcase" aria-hidden="true"></i></div>
        <div className="text">Institution
           Application</div>
      </div>
      <div className="icongrid-item">
        <div className="seticon"><i class="fa fa-address-book" aria-hidden="true"></i></div>
        <div className="text">Educational Counselling</div>
      </div>
      <div className="icongrid-item">
        <div className="seticon"><i class="fa fa-phone" aria-hidden="true"></i></div>
        <div className="text">Flight Booking Service</div>
      </div>
      <div className="icongrid-item">
        <div className="seticon"><i class="fa fa-users" aria-hidden="true"></i></div>
        <div className="text">CV support</div>
      </div>
      <div className="icongrid-item">
        <div className="seticon"><i class="fa fa-graduation-cap" aria-hidden="true"></i></div>
        <div className="text">Training</div>
      </div>
    </section>
    </div>
  );
};

export default IconGridSection;

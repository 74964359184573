import React from 'react';
import '../New.css';
import { Link } from 'react-router-dom';




const Newfooter = () => {
    return (
        <footer className="newfooter">
            <div className='container'>
          <div className="footergrid-container">
            <div className="logo-section">
      
              <h2>Golden Connect Limited</h2>
              <p>Golden Connect Limited is incorporated in the United Kingdom and Nigeria with the corporate affairs commission and certified to Carry out educational trainings globally.</p>
            </div>
            <div className="address-section">

            <h3> <a className='red-bottom' style={{ color: ' #ffffffc7', }} >Con</a>tact Info</h3>
              <div className="icon"  style={{ textAlign: 'left', }} >
                <i className="fa fa-map-marker" style={{color: '#32a4a8'}}></i>
                <p>Suite 5-6, 5th Floor, Block B, Lister House, M.K.O. Abiola Way, Ring Road, Ibadan, Oyo State, Nigeria</p>
              </div>
          
              <Link to={`mailto:info@goldenconnectlimited.com`} style={{textDecoration: 'none'}} className="icon">
                <i className="fa fa-envelope" style={{color: '#32a4a8'}}></i>
                <p>  info@goldenconnectlimited.com</p>
              </Link>
           
              <Link to={`tel:${+2347072680329}`} style={{textDecoration: 'none'}} className="icon">
                <i className="fa fa-phone" style={{color: '#32a4a8'}}></i>
                <p>+2347072680329</p>
              </Link>
              <Link to={`https://wa.me/${+2347072680329}`} style={{textDecoration: 'none'}} className="icon">
                <i className="fab fa-whatsapp" style={{color: '#32a4a8'}}></i>
                <p>+234-7072-680-329 - Nigeria</p>
              </Link>
              <Link to={`https://wa.me/${+447586645941}`} style={{textDecoration: 'none'}} className="icon">
                <i className="fab fa-whatsapp" style={{color: '#32a4a8'}}></i>
                <p>+44-7586-645-941 - UK</p>
              </Link>
              <Link to={`https://wa.me/${+14169070794}`} style={{textDecoration: 'none'}} className="icon">
                <i className="fab fa-whatsapp" style={{color: '#32a4a8'}}></i>
                <p>+1-(416)-907-0794 - Canada</p>
              </Link>
              <Link to={`https://www.instagram.com/goldenconnectltd?igsh=MXBvd2d4YTkycXVy&utm_source=qr`} style={{textDecoration: 'none'}} className="icon">
                <i className="fab fa-instagram" style={{color: '#32a4a8'}}></i>
                <p>Instagram</p>
              </Link>
              <Link to={`https://www.facebook.com/profile.php?id=61559013735678&mibextid=LQQJ4d`} style={{textDecoration: 'none'}} className="icon">
                <i className="fab fa-facebook" style={{color: '#32a4a8'}}></i>
                <p>Facebook</p>
              </Link>
            </div>
            <div className="links-section">
                <h3><a className='red-bottom' style={{ color: ' #ffffffc7', }}> Quic</a>k Links</h3>
                <span></span>
              <ul>
              <Link to="/aboutus"  style={{ color: ' #ffffffc7', textDecoration: 'none' }} onClick={() => {
              window.scroll(0, 0);
            }}>  <li>About us</li> </Link>
             <Link to="/contactus" style={{ color: ' #ffffffc7', textDecoration: 'none' }}  onClick={() => {
              window.scroll(0, 0);
            }}>  <li> FAQs </li></Link> 
              <Link to="/contactus" style={{ color: ' #ffffffc7', textDecoration: 'none' }}  onClick={() => {
              window.scroll(0, 0);
            }}>  <li> Contact us </li></Link> 
              <Link to="/visaApply" style={{ color: ' #ffffffc7', textDecoration: 'none' }}  onClick={() => {
              window.scroll(0, 0);
            }}>  <li> Apply Now</li></Link> 
                {/* <Link to="/TermsAndConditions"  style={{ color: ' #ffffffc7', textDecoration: 'none' }} onClick={() => {
              window.scroll(0, 0);
            }}> <li>Terms & Conditions</li> </Link> */}

           <Link to="/services"  style={{ color: ' #ffffffc7', textDecoration: 'none' }} onClick={() => {
              window.scroll(0, 0);
            }}>  <li>Services</li> </Link>
             {/* <Link to="/Partnerships" style={{ color: ' #ffffffc7', textDecoration: 'none' }}  onClick={() => {
              window.scroll(0, 0);
            }}>  <li>Partnerships</li></Link> 
                */}

               {/* <Link to="/WhoWeAre"  style={{ color: ' #ffffffc7', textDecoration: 'none' }} onClick={() => {
              window.scroll(0, 0);
            }}> <li>Who We Are</li> </Link> */}

{/* <Link to="/login"  style={{ color: ' #ffffffc7', textDecoration: 'none' }} onClick={() => {
              window.scroll(0, 0);
            }}> <li>Goto Classroom</li> </Link> */}

{/* <Link to="/verify"  style={{ color: ' #ffffffc7', textDecoration: 'none' }} onClick={() => {
              window.scroll(0, 0);
            }}> <li>Verify Offer</li> </Link> */}
{/* <Link to="/Testimonials"  style={{ color: ' #ffffffc7', textDecoration: 'none' }} onClick={() => {
              window.scroll(0, 0);
            }}> <li>Testimonials</li> </Link> */}

                
                {/* <li><Link to = "/admin" style={{ color: ' #ffffffc7', textDecoration: 'none' }} >Admin Login</Link></li> */}
              </ul>
            </div>

          </div>
          </div>
        </footer>
      );
    };

export default Newfooter;
